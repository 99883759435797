@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.user-homepage {
  height: auto;
  min-height: 100%;
  overflow-x: hidden;
  padding-bottom: 120px;
  position: relative;

  .user-homepage-container {
    padding-bottom: 120px;
    padding-left: 50px;
    padding-right: 50px;
    @media only screen and (max-width: $media-sizes-medium) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
