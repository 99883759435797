@import '~@shootsta/common-react/dist/ui-kit/scss/main';

.root {
  background-color: $cat-skill-white;
  display: flex;
  flex-direction: column;

  .page {
    max-width: 100%;
  }
}

.sentry-error-embed {
  p,
  h2 {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    letter-spacing: 1px;
  }
}

.library {
  .list-container {
    &__content {
      &--grid {
        grid-auto-rows: minmax(200px, max-content);      
      }
    }
  }

  .modal {
    overflow-y: hidden;

    .stock-assets-modal {
      &__summary {
        bottom: 36px;
        position: absolute;
      }
    }
  }
}

// temporary hack to fix styles, need to apply to common-react
.share-settings-form {
  .accordion {
    &__title-wrapper {
      margin: 10px 0;
    }

    .icon-container {
      margin-right: 12px;
    }

    .hubspot-icon {
      background-image: url('../assets/images/hubspot-logo.svg');
      background-position: 0%;
      background-size: 95%;
      height: 30px;
      margin-right: 3px;
      position: relative;
      width: 30px;
    }
  }
}

/* stylelint-disable selector-max-id */
#hubspot-messages-iframe-container.hubspot-widget-launcher,
#hubspot-messages-iframe-container.widget-align-right,
#hubspot-messages-iframe-container.widget-align-left
{
  bottom: 75px !important;
}
