@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.quick-actions {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  gap: 25px;
  width: 100%;
  margin-bottom: 30px;
  user-select: none;

  &__link {
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    max-height: 55px;
  }

  &__title {
    margin: 0;
    font-weight: 600;
  }

  &__subtitle {
    margin: 0;
    font-size: $font-sizes-micro;
    color: $rasping-sandy;
  }

  &-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: unset;

    &__info {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__icon {
      width: 55px;
      height: 55px;
      border-radius: 10px;

      &-image {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    &-card {
      width: unset;
    }

    &-card:nth-child(3) {
      grid-column: span 2;
    }
  }

  @media only screen and (min-width: 1280px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-card {
      width: 30%;
    }
  }
}

.dark-purple {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(225deg);
}

.dark-yellow {
  filter: invert(90%) sepia(51%) saturate(2878%) hue-rotate(15deg)
    brightness(120%);
}

.dark-blue {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(180deg);
}

.purple {
  background-color: rgba(20, 0, 255, 0.1);
}

.yellow {
  background-color: rgba(255, 200, 0, 0.13);
}

.blue {
  background-color: rgba(0, 150, 255, 0.13);
}
