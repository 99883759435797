/* stylelint-disable selector-max-compound-selectors */

@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.artical-box {
  margin-top: 30px;
  border-radius: 5px 5px 0 0;
  padding: 32px;
  height: 100%;
  min-height: 220px;

  .logo {
    margin-bottom: 48px;
  }

  .text {
    color: #fff;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 1px;
  }

  &___card-button {
    align-self: center;
    .text {
      font-size: 14px;
      color: #27baa1;
      text-decoration: none;
    }
    border-radius: 200px;
    padding: 6px 12px 6px 12px;
    margin-top: 24px;
    width: 91px;
    height: 32px;
    min-height: 32px;
    background-color: rgba(39, 186, 161, 0.15);
    text-decoration: none;
  }

  &___card-button:hover {
    background-color: $monkey;
    .text {
      color: white;
    }
  }

  &___button {
    align-self: center;

    .text {
      font-size: 14px;
      text-decoration: none;
    }

    padding: 10px 26px;
    margin-top: 24px;
    width: 100%;
    max-width: 210px;
    color: $white;
    background-color: $another-gray;
    text-decoration: none;
    &:hover {
      background-color: #4b5868 !important;
    }
  }
}

.article-banner {
  padding: 0;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    min-height: 81pxpx;

    &-title {
      color: $black;
      align-items: flex-start;
      font-size: $font-sizes-semilarge;
      line-height: 150%;
      font-weight: 600;
      height: 81px;
      max-height: 81px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-span {
      margin-top: 13px;
      cursor: pointer;
    }
  }

  &__banner {
    background-repeat: no-repeat;
    height: 100%;
    width: 40%;
  }

  .slick-arrow {
    display: none;
    top: 45%;
  }

  .card-link {
    text-decoration: none;
  }

  .card-item {
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 20px;
    margin-top: 20px;
    min-width: 200px;
    padding: 30px;
    width: 100%;

    &__content {
      padding: 0;

      .card-text {
        .text {
          color: $nevada;
          margin: 0 auto;
          font-size: $font-sizes-small;
          font-weight: 400;

          &--body {
            justify-content: left;
            text-align: left;
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $media-sizes-medium) {
    .slick-arrow {
      display: flex;
    }
  }
}
