@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';
@import '~@shootsta/common-react/dist/ui-kit/scss/core/mixins';

$logo-height: 130px;

.login {
  height: 100%;

  &__side-panel {
    display: flex;
    height: 100%;
    position: relative;
    width: 55%;

    > svg {
      fill: #c8ebe3;
      height: 100%;

      &:last-of-type {
        fill: $aqua;
        left: 30px;
        opacity: 0.1;
        position: absolute;
        top: 50px;
        transform: scaleY(-1);
        z-index: -1;
      }
    }

    &__inner {
      background: #c8ebe3;
      flex: 1;
      height: 100%;

      > svg {
        left: 75px;
        position: absolute;
        top: 50%;
        transform: scaleX(-1) translateY(-50%);
        width: 120%;
      }
    }
    
    @media only screen and (max-width: $media-sizes-medium) {
      display: none;
    }
  }

  &__container {
    display: flex;
    height: 100vh;
    justify-content: space-between;
    overflow: hidden;

    @media only screen and (max-height: $media-sizes-small - $logo-height) {
      overflow: unset;
      overflow-y: scroll;
    }
  }

  &__left-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 40%;

    @media only screen and (max-width: $media-sizes-large) {
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
    }
  }
}
