@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.outputs-list {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;

  > .text {
    padding-left: 20px;
    padding-right: 20px;
  }

  > .link {
    display: inline-block;
    margin-left: 20px;
    margin-top: 5px;
  }

  &__output {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;

    &__info {
      flex: 1;
      margin-left: 10px;
      margin-right: 10px;
      overflow: hidden;

      &__status {
        text-transform: capitalize;
      }
    }
  }
}
