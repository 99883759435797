@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.product-banner {
  cursor: pointer;
  height: 180px;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 0;
  position: relative;

  &__banner-content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
    z-index: 2;

    &__default-click-area {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
    
    &__header-title {
      font-size: $font-sizes-large;
      line-height: 150%;
      padding: 65px;
      width: 40%;
    }

    &__cta-default {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 65px;
    }

    &__cta-list-container {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      list-style-type: none;
      margin: 0;
      padding: 0;
      padding-right: 35px;
      position: relative;
      width: 40%;
      z-index: 3;

      &__cta-list-button {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        cursor: pointer;
        height: 140px;
        margin: 5px;
        margin-top: 10px;
        width: 140px;
      }

      &--stack-column {
        flex-direction: column;
        height: 120px;
        width: 185px;
      }

      &__cta-list-button-text {
        padding-right: 15px;
        text-align: right;
      }
      @media only screen and (max-width: 1200px) {
        justify-content: center;
        padding-right: 0;

        &__cta-list-button-text {
          display: none;
        }
      }
      @media only screen and (max-width: 1000px) {
        padding-right: 0;
        width: 100%;

        &__cta-list-button {
          margin: 0;
        }
      }
    }
  }

  &__banner-background-image {
    background-position: 70% bottom;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    height: 100%;
    left: 0;
    max-height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  @media only screen and (min-width: 2200px) {
    &__banner-background-image {
      background-size: 15%;
    }
  }

  @media only screen and (max-width: 1500px) {
    &__banner-background-image {
      background-size: 25%;
    }
  }

  @media only screen and (max-width: 1200px) {
    &__banner-content {
      &__header-title {
        width: 50%;
      }
    }

    &__banner-background-image {
      display: none;
    }
  }

  @media only screen and (max-width: 1000px) {
    height: auto;
    padding-bottom: 35px;

    &__banner-content {
      flex-direction: column;

      &__header-title {
        padding: 35px;
        padding-bottom: 30px;
        text-align: center;
        width: 80%;

      }

      &__cta-default {
        margin: 0;
      }
    }

    &__banner-background-image {
      display: none;
    }
  }
}
