@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

@mixin login-form-mixin() {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 80%;

  &__close-btn {
    left: 0;
    position: absolute;
    top: 30px;
  }

  @media only screen and (max-width: $media-sizes-small) {
    align-items: center;
    width: 100%;

    &__close-btn {
      top: 10px;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .login-form__code {
      input {
        font-size: 24px !important;
        width: 45px !important;
      }
      
    }

  }

  &__field {
    display: block;
    max-width: 380px;
    width: 100% !important;

    &:first-of-type {
      margin-bottom: 20px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__submit {
    height: 40px;
    margin-top: 30px;
    max-width: 380px;
    min-height: 40px;
    width: 100% !important;
  }
}
