@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.tabsV2 {
  .tab {
    justify-content: flex-start;
    white-space: nowrap;

    .text {
      color: $nevada !important;
    }

    &--selected {
      border-bottom: 3px solid $black;
      transition: left 0.2s ease-in-out;

      .text {
        color: $black !important;
      }
    }
  }

  .tabs {
    border-bottom: 1px solid $russian;
    display: flex;

    &__selected-line {
      height: 0;
    }
  }
}
