@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

@mixin card-shadow {
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
}

.project-template-preview-modal {
  @include card-shadow;
  background-color: #fafafa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
}

.modal__header__text {
  a {
    color: $monkey;
    text-decoration: none;

    &:hover {
      color: $aqua;
      text-decoration: underline;
    }
  }
}