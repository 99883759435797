@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.view-all-notifications {
  margin: auto;
  max-width: 1000px;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    &__title {
      color: $black;
      font-size: 30px;
      letter-spacing: 2px;
      line-height: 35px;
      margin-bottom: 25px;
      text-align: center;
    }

    &__indicator {
      align-items: center;
      background-color: $monkey;
      border-radius: 50%;
      color: $white;
      display: flex;
      height: 20px;
      justify-content: center;
      margin: -55px 0 -15px;
      width: 20px;

      &__text {
        font-size: 10px;
      }
    }
  }

  &__mark-read {
    margin-left: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 10px 75px 50px;
  }

  &__section {
    margin: 50px 0 0;

    &__title {
      margin: 15px 0;
    }

    &__item {
      align-items: center;
      background: $white;
      border: 1px solid $sterling;
      border-radius: 6px;
      display: flex;

      //&:not(:last-of-type) {
      margin-bottom: 10px;
      padding: 20px 30px;
      text-decoration: none;
      //}

      &:hover {
        background: $parthenon;
      }

      &__unread {
        background: $monkey;
        border-radius: 50%;
        display: block;
        height: 15px;
        margin-left: 20px;
        min-width: 15px;
        width: 15px;
      }

      &__content {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: 100%;

        > .text:first-of-type {
          margin-left: 30px;
        }
      }
    }

    &__no-items {
      display: flex;
      height: 100%;
      justify-content: center;
      padding: 30% 0;
    }
  }
}
