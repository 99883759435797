@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.top-up-credits-modal {
  &__balance {
    display: flex;
    background-color: $cat-skill-white;
    padding: 16px;
    margin-bottom: 36px;

    &__title {
      padding-left: 16px;

      .current-balance-title {
        margin-bottom: 0;
      }
      .credits-remaining {
        color: $dark-denim;
        margin-top: 0;
      }
    }
  }

  &__purchase-box {
    padding: 16px;
    background-color: $cat-skill-white;

    &__quantity {
      &__input {
        position: relative;

        .input-label {
          .input-container {
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              /* stylelint-disable property-no-vendor-prefix */
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }

        .credit-suffix {
          font-size: 14px;
          position: absolute;
          right: 16px;
          top: 41px;
        }
      }
    }

    &__summary {
      margin-top: 36px;

      .price-per-credit {
        color: $dark-denim;
        font-weight: 600;
      }

      &__credit-amount {
        display: flex;
        justify-content: space-between;

        .credit-price {
          color: $dark-denim
        }
      }

      .separator {
        color: $russian;
      }
    }

    &__amount-due {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
