@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.product-banner-large {
  cursor: pointer;
  height: 320px;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 0;
  position: relative;

  &__banner-content {
    height: 100%;
    position: absolute;
    right: 0;
    width: 50%;
    z-index: 1;

    &__header-title {
      font-size: $font-sizes-large;
      line-height: 150%;
      padding: 65px;
      padding-bottom: 35px;
      padding-right: 0;
      width: 50%;
    }

    &__cta-default {
      align-items: center;
      background-color: #4b5868;
      display: flex;
      justify-content: center;
      margin: 65px;
      margin-top: 0;
    }
  }

  &__banner-background-image {
    background-position: 20% bottom;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    height: 90%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  
  @media only screen and (max-width: $media-sizes-medium) {
    height: 230px;

    &__banner-content {
      width: 100%;

      &__header-title {
        margin: 0 auto;
        padding: 0;
        padding-bottom: 30px;
        padding-top: 50px;
        text-align: center;
        width: 90%;
      }
  
      &__cta-default {
        margin: 0 auto;
      }
    }

    &__banner-background-image {
      display: none;
    }
  }
}
