.additional-fields-preview-container {
  .elevated-container--rounded {
    padding: 32px;
  }

  .additional-fields-preview-heading {
    margin-bottom: 40px;
  }

  .additional-fields-preview-content {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .project-additional-field {
      &__selection-list, &__carousel {
        margin-top: 0;
      }
    }

    .text {
      &__asterisk {
        color: #ED1010;
      }
    }
  }
}