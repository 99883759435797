@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.missing-fields-modal {
  &__fields {
    border: 1px solid $russian;
    border-radius: 6px;
    margin-bottom: 20px;
    margin-top: 40px;
    padding: 20px;

    &__field {
      align-items: center;
      display: flex;
      gap: 10px;

      > button {
        height: 40px;
        margin-left: auto;
        min-height: 40px;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $russian;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
    }
  }

  &__footer {
    align-items: baseline;
    display: flex;

    p > .text {
      display: contents;

      &--link {
        font-size: 12px;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  }
}
