@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.workspaces-items {
  margin-top: 50px;
  max-height: 303px;
  min-width: 350px;
  overflow-y: auto;
  width: 100%;

  .workspaces {
    &-item {
      align-items: center;
      border-top: 1px solid $russian;
      display: flex;
      height: 100px;
      justify-content: space-between;
      width: 100%;

      &__info {
        align-items: center;
        display: flex;
        text-align: start;
        width: 80%;

        &-name {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          width: 75%;
        }

        .image {
          min-width: inherit;
        }
      }

      .launch {
        @media only screen and (max-width: $media-sizes-medium) {
          display: none;
        }
      }

      .return {
        display: none;
        transform: rotate(180deg);

        &:hover {
          cursor: pointer;
        }

        @media only screen and (max-width: $media-sizes-medium) {
          display: block;
        }
      }

      g {
        stroke: none;
      }
    }
  }

  &-help-info {
    margin-top: 50px;
    text-align: left;

    .text {
      display: inline;
    }

    .link {
      text-decoration: none;
    }
  }
}
