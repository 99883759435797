/* stylelint-disable selector-max-compound-selectors */

@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.how-to-carousel {
  padding: 0;
  padding: 20px;
  position: relative;

  &__header-title {
    color: $black;
    position: relative;
  }

  .slick-arrow {
    display: none;
    top: 45%;
  }

  .card-link {
    text-decoration: none;
  }

  .card-item {
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 20px;
    margin-top: 20px;
    min-width: 200px;
    width: 100%;

    &__content {
      padding: 0;
      
      .card-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 160px;
        width: 100%;
      }

      .card-text {
        .text {
          color: $monkey;
          margin: 0 auto;
          padding-top: 10px;

          &--body {
            justify-content: center;
            text-align: center;
            width: 75%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $media-sizes-medium) {
    .slick-arrow {
      display: flex;
    }
  }

}
