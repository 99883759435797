@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 200, 80, 0.7);
    transform: scale(0.95);
  }

  70% {
    box-shadow: 0 0 0 7px rgba(255, 200, 80, 0);
    transform: scale(1);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 200, 80, 0);
    transform: scale(0.95);
  }
}

header {
  width: 100%;

  .global-header-container {
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $russian;
    display: flex;
    height: $header-height;
    justify-content: flex-end;
    padding-top: 1px;
    position: sticky;
    top: 0;
    z-index: $header-z-index;

    .workspace-logo {
      margin-right: auto;
      cursor: pointer;
    }

    #credits-info {
      margin-right: 24px;
      #credits-info-btn {
        min-height: 38px !important;
        width: 112px;

        .button__content {
          .text--button {
            color: $black;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }

    &__changes-container {
      border-radius: 50%;
      cursor: pointer;
      margin-right: 30px;

      /* stylelint-disable-next-line selector-class-pattern */
      .Canny {
        &_Badge {
          // 60s of pulse, 2s per pulse = iteration count of 30
          animation: pulse 2s 30;
          background-color: $golden-tainoi;
          border: 2px solid $white;
          border-radius: 50%;
          box-shadow: 0 0 0 0 rgba(255, 200, 80, 1);
          box-sizing: border-box;
          color: $white;
          display: flex;
          height: 18px;
          justify-content: center;
          position: absolute;
          right: -9px;
          top: -9px;
          transform: scale(1);
          width: 18px;
        }
      }
    }

    > .notifications-summary {
      &__container {
        margin-right: 30px;
      }
    }

    > .global-credits-wrapper {
      margin-right: 30px;
      padding-right: 20px;
      position: relative;

      &::after {
        background: $russian;
        content: '';
        height: 30px;
        position: absolute;
        right: 0;
        width: 1px;
      }
    }
  }
}

.nav-menu-mobile {
  position: absolute;
  top: 0;
  width: $header-menu-mobile-icon-width !important;
  z-index: 10;

  /* stylelint-disable-next-line selector-class-pattern */
  .nav-menu-mobile__header {
    background: none;
    width: $header-menu-mobile-icon-width;
  }

  .icon-container:nth-of-type(2) {
    display: none;
  }
}
