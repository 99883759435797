@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.floating-window-container {
  &.expand-drag-container {
    width: 480px;
    border-radius: 8px 0px 0px 0px;
    transition: width 0.3s ease, height 0.3s ease;

    &--collapsed {
      width: 237px;
    }

    .expand-drag-container--header {
      background-color: #222222;
      box-shadow: none;
      padding: 10px 25px;
      padding-bottom: 0;


      &--collapsed {
        padding: 0 25px;
        padding-bottom: 0;
      }
    }

    .expand-drag-container--body {
      box-shadow: none;
      background-color: #222222;
      padding-top: 0;

      &--hidden {
        display: none;
      }
    }
  }

  &__header {
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
  }
}

.content-container {

  &__wizard {
    padding: 20px 10px;
    padding-top: 0;

    .wizard__progress-block-container {
      top: 15px;
    }
  }

  .wizard__content {
    padding-top: 0;
  }

  &__heading {
    padding: 20px 10px;
    padding-top: 0;


    &__title {
      color: white;
    }

    &__subtitle {
      color: #8C96A9;
    }
  }

  .wizard-body {
    width: 100%;
    padding-top: 15px;

    &__skip {
      margin-top: 16px;

      a {
        text-decoration: none;
      }

      .text {
        color: $white;
        font-weight: 500;
        justify-content: center;
        line-height: 24px;
      }
    }

    &__finish-content {
      &__item {
        padding-bottom: 40px;
      }

      &__terms-condition {
        padding-bottom: 32px;

        &__subtext,
        &__text {
          color: #8c96a9;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-align: start;
          display: contents;

          &__link {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            color: #28baa1;
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .check-box__content {
          margin-left: 10px;
        }
      }
    }

    &__kyc-content {
      &__work-dropdown,
      &__age-dropdown {
        .react-select__placeholder {
          font-weight: unset;
          color: #696d74;
        }
      }

      >label {
        padding-bottom: 40px;

        >div>p {
          color: white;
        }
      }
    }
  }
}

div#hubspot-messages-iframe-container.hubspot-bitool {
  bottom: 10px !important;
  right: 260px !important;

  &__expanded {
    right: 520px !important;
  }
}