@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.additional-field {

  &__carousal-width {
    .card-item {
      width: 280px !important;
      margin-right: 0px !important;

      .asset-preview {
        .image-preview {
          >div {
            background-size: contain !important;
          }
        }
      }
    }

    .slick {
      &-slider {
        .slick-list {
          .slick-track {
            width: auto !important;

            .slick-slide {
              width: auto !important;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .elevated-container {
    &--rounded {
      box-sizing: border-box;
      margin-bottom: 40px;
      padding: 40px;
    }
  }

  .card-item {
    border-radius: 4px;
    height: 227px;
    margin-bottom: 8px;
    position: relative;
    margin-right: 32px;
    box-shadow: none;
    border: 1px solid $russian ;

    &__content {
      >.check-box {
        border-radius: 10px;
        left: 0;
        padding: 8px;
        position: absolute;
        top: 0;
      }
    }
  }

  .slick {
    &-slider {
      .carousel-dots {
        justify-content: center;
        margin: 32px 0;
        transform: unset;
      }
    }
  }

  &__card-icon {
    display: flex;
    justify-content: center;
  }

  &__field {
    align-items: flex-end;
    display: flex;
    gap: 1rem;
    margin-bottom: 8px;
    width: 100%;

    &__arrow-button {
      margin-bottom: 8px;
    }

    &__input {
      flex-grow: 1;
    }

    &__title {
      font-size: $font-sizes-small;
      font-weight: $font-weights-medium;
      line-height: 18px;
    }

    &__checkbox {
      left: 15px;
      position: absolute;
      top: 15px;
      z-index: 2;
    }

    &__subtitle {
      color: $nevada;
      font-size: $font-sizes-small;
      font-weight: $font-weights-regular;
      line-height: 18px;

      .text--link {
        font-size: $font-sizes-small;
        line-height: 18px;
      }
    }
  }

  &__description {
    margin-top: 40px;
  }

  .text--heading4 {
    line-height: 27px;
  }

  .text--subtitle {
    font-size: $font-sizes-small;
    line-height: 18px;
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    >button {
      margin-left: 20px;
    }
  }
}