@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.user-wrapper-container {
  display: flex;
  height: 35px;
  justify-content: flex-end;
  padding-bottom: 4px;
  user-select: none;

  .menu-button {
    cursor: pointer;

    &__menu {
      padding: 0;
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  &__userprofile-box {
    &__icon {
      align-items: flex-start;
      display: flex;
      margin-left: 10px;

      &__ghost {
        margin-right: 20px;
      }
    }
  }
}
