@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.core-layout {
  display: grid;
  flex: 1;
  grid-template-columns: minmax(min-content, max-content) minmax(0, auto);
  grid-template-rows: 1fr;
  // This is to enable infinite scrolling on lists inside apps - DO NOT REMOVE
  // If your app doesn't scroll, it needs overflow: auto at the top level
  overflow-y: hidden;

  >*:first-child {
    position: relative;
    z-index: 2;
  }

  &__content {
    display: flex;
    flex-direction: column;
    // This is to enable infinite scrolling on lists inside apps - DO NOT REMOVE
    // If your app doesn't scroll, it needs overflow: auto at the top level
    overflow-y: hidden;
  }

  &__no-scroll {
    overflow: hidden;
  }

  &__with-no-sidebar {
    grid-template-columns: minmax(0, auto);

    .drawer--left-align {
      display: none;
    }
  }
}

@media screen and (max-width: $media-sizes-small) {
  .core-layout {
    grid-template-columns: minmax(0, auto);
  }
}
