.payment-failed-modal {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  &__title {
    font-size: 24px;
    line-height: 36px;
    text-align: left;
    padding-top: 24px;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}