@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.user-availability {
  box-sizing: border-box;
  width: 100%;

  &__editors {
    align-items: center;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;

    > *:first-child {
      margin-right: 5px;
    }
  }

  &__line {
    background: $big-fat;
    border-radius: 12px;
    box-sizing: border-box;
    height: 5px;
    margin-bottom: 10px;
    margin-left: 20px;
    overflow: hidden;
    width: calc(100% - 40px);

    &--progress {
      background: $monkey;
      height: 100%;
      transition: width 0.5s;
    }
  }

  &__availability {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
