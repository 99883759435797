.flex-purchase-payment-success-modal {
  padding: 10px;

  &__heading {
    display: flex;
    align-items: flex-start;
    flex-direction: column;


    &__title {
      font-size: 24px;
      line-height: 36px;
      text-align: left;
      padding-top: 24px;
    }

    &__subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }

  &__content {
    margin-top: 5px;
    padding: 16px;
    gap: 16px;
    border-radius: 8px;
    background-color: #FAFAFB;
  }

}