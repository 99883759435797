@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.recent-carousel {
  margin-bottom: 30px;
  margin-top: 30px;

  .slick-arrow {
    top: 45%;
  }

  .recent-carousel-container {
    margin: 0 auto;
    max-width: 90%;
    overflow: hidden;
    width: 100%;

    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &-title {
        color: $black;
        margin-bottom: 15px;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .card-item {
    height: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    min-height: 300px;
    width: 95%;

    &-single {
      @media only screen and (min-width: $media-sizes-small) {
        width: 359px;
      }
    }

    &__content {
      padding: 0;

      .readable-id {
        background-color: $black-75;
        border-radius: 4px;
        color: $white;
        line-height: 100%;
        padding: 7px 12px;
        pointer-events: none;
        position: absolute;
        right: 15px;
        top: 172px;
      }

      .image {
        background-color: $russian;
        cursor: pointer;

        &__icon {
          background-color: $russian;
        }
      }

      .content-status {
        border-radius: 4px;
        color: $white;
        font-size: 14px;
        left: 15px;
        padding: 6px 25px;
        position: absolute;
        text-transform: capitalize;
        top: 180px;
      }
      
      .content-title {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
      }

      .content-published-date {
        padding-bottom: 1rem;
      }

      .content-menu {
        bottom: 20px;
        position: absolute;
        right: 15px;
      }

    }

    .image {
      min-height: 222px;
      width: 320px;
    }
  }

  &__body {
    flex-direction: row;
    height: 100%;
    justify-content: space-between;

    &__description {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      &__name {
        .text {
          display: inline-block;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @media screen and (max-width: $media-sizes-small) {
            max-width: 200px;
          }
        }
      }
    }
  }
}
