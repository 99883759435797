@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.login-header {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  margin-top: auto;

  &__icon {
    height: 130px !important;
    margin-bottom: 40px;
    max-width: 500px;
  }

  @media screen and (max-width: $media-sizes-small) {
    &__icon {
      margin-bottom: 20px;
      max-width: 380px;
    }
  }

  > .text {
    text-align: left;
  }

  > h2,
  > h4 {
    width: 100%;
  }
}
